const Menu = [

    {
        id: 1,
        image: "images/mdmc_const_net_1.jpg",
        name: "Sinkor",
        category: "Constructions",
        projectDetailPage: "/projectDetails/About.html",
        description:
            "This multistoreied comlex started in 2020 and near completion, we met the timelines and the budget. We used the quality material and undertaken all the saftey meaures as per the international standards",
    },
    {
        id: 2,
        image: "images/mdmc_road_const_boss_5.jpg",
        name: "Pipeline",
        category: "Infrastructure",
        projectDetailPage: "/projectDetails/About.html",
        description:
            "15 KM road laying completed as per the standards and specification with high grade material. We are the key layer in the field, each and every time we deliver the best and learn from every single project and improvise for the next project, we get stronger and robust after every project ",
    },
    {
        id: 3,
        image: "images/mdmc_it_1.jpg",
        name: "MoT",
        category: "IT",
        projectDetailPage: "/projectDetails/MoT.html",
        description:
            "We are young and enthusiastic IT startup company under the huge umbrella of our parent company MDMC Express, Inc. Ministry of Transport is our first project under IT, we are supporting and maintaining the end-end project for vehicle registrations, Driving License and Business Eligibility.",
    },
    {
        id: 4,
        image: "../images/mdmc_const_1.jpg",
        name: "13th Street",
        category: "Constructions",
        projectDetailPage: "/projectDetails/About.html",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
    {
        id: 5,
        image: "../images/mdmc_const_logo.jpg",
        name: "MDMC",
        category: "About",
        projectDetailPage: "/projectDetails/About.html",
        description:
            "MDMC Express, Inc. is one hundred percent indigenous Liberian Company established in 1986 as a waste management company. Over the years, it has transformed itself into civil engineering company with emphasis on horizontal and vertical construction. MDMC has had road construction projects with the Ministry of Public Works and the private sector in Liberia. MDMC is registered with the Ministry of Public Works as 'A.1' General Road Construction company.",
    },
    {
        id: 6,
        image: "../images/mdmc_road_const_2.jpg",
        name: "Johnsonville piepline",
        category: "Infrastructure",
        projectDetailPage: "/projectDetails/About.html",
        description:
            "We have done asphalt works in communities in Montserrado County, Pipeline to Johnsonville 6.5km, Johnsonville to White Plains 12km and Johnsonville to Mount Barclay Roads 6.5km. We have expertise in Culvert/box/Bridge culverts installation and bridges. ",
    },
];

export default Menu;
