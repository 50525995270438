import React from "react";
import About from "../About";
import { MdmcAbout } from "./MdmcAbout";

const MdmcDepartmentCards = ({ menuData, redirectPage }) => {
    // console.log(menuData);

    if (menuData[0].category === "About") {

        return (
            <>
                <MdmcAbout />
            </>
        );

    } else {

        return (

            <>
                <section className="main-card--cointainer" >
                    {menuData.map((curElem, i) => {
                        const { id, name, category, image, description, projectDetailPage } = curElem;

                        // console.log(curElem);
                        return (

                            <>
                                <div className="card-container" >

                                    <div className="card" key={i}>
                                        < div className="card-body" >
                                            <span className="card-number card-circle subtle" >{i + 1}</span>
                                            <span className="card-author subtle" > {category}</span>
                                            <h2 className="card-title" > {name} </h2>
                                            <span className="card-description subtle" >
                                                {description}
                                            </span>
                                            <div className="card-read" >
                                                <li onClick={() => redirectPage(projectDetailPage)}> Read</li>
                                                {/* <li onClick={() => redirectPage("/projectDeatils/" + name + ".html")} > Read</li> */}
                                                {/* <a href="../component/About.html" onClick={() => window.open('/component/About.html')}>Hello</a> */}
                                                {/* <a href="../About.html" onClick={() => openInNewTab('https://google.com')}>Hello</a> */}
                                                {/* <a onClick={() => openInNewTab('/component/About.html')} >Hello</a> */}

                                            </div>
                                        </div>
                                        <br />
                                        <img src={image} alt="images" className="card-media" />

                                        {/* <span className="card-tag  subtle">Order Now</span> */}
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </section >

            </>
        );
    };
};

export default MdmcDepartmentCards;
