import React from "react";
import { BussList } from "./Mdmc_Buss_List";

const Navbar = ({ filterItem, menuList }) => {
    //const BussList = ["Constructions", "Road Laying", "IT", "About"];

    //console.log(BussList);
    return (
        <>

            <nav className="navbar">
                <div >
                    <table width="100%">
                        <tr >
                            <td width="20%" >
                                <img className="iconImage" src="../images/mdmc_logo_irf1.jpg" width={"70%"} alt="Hello MDMC" />
                            </td>
                            <td width="80%" align="center">
                                <div className="btn-group">

                                    {BussList.map((curElem, i) => {
                                        return (
                                            <button
                                                className="btn-group__item"
                                                // onClick={() => filterItem(curElem)} key={i}>
                                                onClick={() => filterItem(curElem)} key={i}>
                                                {curElem}
                                            </button>
                                        );
                                    })}
                                </div>
                            </td>
                        </tr>
                    </table>

                </div>
            </nav>
        </>
    );
};

export default Navbar;
