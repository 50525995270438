import React, { useState } from "react";
import MdmcMainPage from "./component/Basics/MdmcMainPage";
// import NewWindow from 'react-new-window';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import About from "./component/About";
import { BussList } from "./component/Basics/Mdmc_Buss_List";
import MdmcFooter from "./component/Basics/MdmcFooter";
// import Navbar from "./component/Basics/NavBar";


// const openInNewTab = url => {
//   return (
//     <NewWindow >
//   // window.open(url, '_blank', 'noopener,noreferrer');
//       <About />
//     </NewWindow>
//   );
// };


const App = () => {
  const [homeClick, sethomeClick] = useState(true);
  const filterItem = null;
  return (
    <About />
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<Layout />}>
    //       <Route index element={<About homeClick />} />
    //       <Route path="About" element={<About homeClick />} />
    //       <Route path="contact" element={<MdmcFooter />} />
    //       <Route path="*" element={<MdmcFooter />} />
    //     </Route>
    //   </Routes>
    // </BrowserRouter >
    // onClick={() => sethomeClick(false)}
  );


  /* Below is working code */

  // return (
  //   <div className="pageBackgroundImage">
  //     {/* <a onClick={() => openInNewTab('')} >Hello</a> */}
  //     {/* <button onClick={() => { <MdmcMainPage /> }} >Hello</button> */}
  //     <MdmcMainPage />
  //     <span>
  //       <br></br>
  //     </span>
  //   </div>
  // );

};

export default App;