import React from 'react';

import "./mdmc_footer.css";

const MdmcFooter = () => {
  return (
    // <div >
    //   <a href="/" > Seeeeeee </a>
    //   <p >
    //     This the fotter page


    //     Come and work
    //   </p>
    // </div >




    <div class="container">
      <div class="row">
        <div class="footer-col">
          <h4>Our Services</h4>
          <ul>
            <li><a href="#">Information Technology</a></li>
            <li><a href="#">Construction</a></li>
            <li><a href="#">Infrastructure</a></li>
            <li><a href="#">Crane (Hydraulic equipments)</a></li>
            <li><a href="#">Asphalt</a></li>
            <li><a href="#">Rock Quarry</a></li>
            <li><a href="#">Equipment Rental</a></li>
            <li><a href="#">Real Estate</a></li>
            <li><a href="#">Transport</a></li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Our Clients</h4>
          <ul>

            <li><a href="#">Ministry of Public Works</a></li>
            <li><a href="#">Ministry of Agriculture</a></li>
            <li><a href="#">Ministry of Transport</a></li>
            <li><a href="#">US Embassy</a></li>
            <li><a href="#">UNHCR</a></li>
            <li><a href="#">WFP</a></li>
            <li><a href="#">MSF/Belgium</a></li>
            <li><a href="#">Bong and Margibi County Local Government</a></li>
            <li><a href="#">Bong Mining Company</a></li>
            <li><a href="#">LACE</a></li>
            <li><a href="#">LSFRP</a></li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Contacts</h4>
          <ul>
            <li><a href="#">Email : mdmcexpress@gmail.com</a></li>
            <li><a href="#">Phone : +231 777512431</a></li>
            <li><a href="#">Address : Sinkor, Liberia</a></li>

          </ul>
        </div>
        <div class="footer-col">
          <h4>follow us</h4>
          <div class="social-links">
            <a href="../Basics/About.html"><i class="fab fa-facebook-f" ></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fab fa-linkedin-in"></i></a>
          </div>
        </div>
      </div >
    </div >



  )
}

export default MdmcFooter
