import React, { useState } from "react";
import "./style.css";
import MdmcMenu from "./Mdmc_Data.js";
import MdmcDepartmentCards from "./MdmcDepartment";
import Navbar from "./Navbar";

const uniqueList = [
    ...new Set(
        MdmcMenu.map((curElem) => {
            return curElem.category;
        })
    ),
    "About",
];

const updatedList1 = MdmcMenu.filter((curElem) => {

    return curElem.category === "About";
});

// console.log(uniqueList);



const MdmcMain = (isFirst) => {
    const [menuData, setMenuData] = useState(updatedList1);
    const [menuList, setMenuList] = useState(uniqueList);

    const filterItem = (category) => {

        if (category === "All") {
            setMenuData(MdmcMenu);
            return;
        }
        const updatedList = MdmcMenu.filter((curElem) => {
            return curElem.category === category;
        });

        setMenuData(updatedList);
    };

    const redirectPage = (pageName) => {
        window.open(pageName, '_self', 'Irfan');
        // window.open("wwww.google.com");
        // alert("Details coming soon.....");
        return;
    };


    return (
        <>
            <div>
                <Navbar filterItem={filterItem} menuList={menuList} />
            </div>
            <span></span>
            <div>
                <MdmcDepartmentCards menuData={menuData} redirectPage={redirectPage} />
            </div>


        </>
    );

};

export default MdmcMain;
