import React from "react";
import MdmcFooter from "./Basics/MdmcFooter";
import MdmcMainPage from "./Basics/MdmcMainPage";


const About = (homeClick) => {
  //alert("IRfan    basha");
  return (

    <div>
      < div className="pageBackgroundImage" >
        <MdmcMainPage homeClick={homeClick} />
      </div>
      <div className="footer">
        <MdmcFooter />

      </div >
    </div>

  )

};

export default About;