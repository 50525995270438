import React from 'react';
import "./mdmc_about.css";

export const MdmcAbout = () => {
  return (
    // <div>MdmcAbout
    //   <div>
    //     <div>
    //       <img src="images/mdmc_const_net_1.jpg" alt="images" className="card-media" />
    //     </div>
    //   </div>
    // </div>

    <div>
      {/* <div className="about-section"> */}
      <div className="container-p">
        <div className="container-p">
          <div className="row">
            <div style={{ width: "70%" }} className="column-p">
              <h1>
                <text>
                  <text style={{ color: "red" }}> M
                    <text style={{ color: "blue" }}> D M C Express, Inc.</text>
                  </text>
                </text>
              </h1>
              <p className='about-paragraph' >
                <text>
                  <b> MDMC Express, Inc. </b>is indigenous proud & trusted Liberian Company, established in 1986 as a waste management company. Over the years, we spanned into multi business and transformed itself into a core civil engineering company with emphasis on horizontal and vertical construction and spanning into information technology space.
                </text>
                <br />
                <br />
                <text style={{ color: "#76c756 " }}>
                  MDMC is the trusted company in the area of road construction projects and working along with the Ministry of Public Works and the private sector in Liberia and delivered successful projects beyond customers positive expectations. MDMC is registered with the Ministry of Public Works as "A.1" General Road Construction company.
                </text>
                <br />
                <br />
                MDMC fully own ASPHALT, ROCK CRUSHER and CONCRETE BATCHING PLANT in Liberia helps in end-end operations with no or less dependency on other companies in the area of constructions and road laying. Over the years we have mastered in the construction and of all types (Asphalt or Gravel) of roads laying in the country.  Here are few successfully completed projects the Magribi, Bong, Nimba, Montserrado, Grand Gedeh, Grand Cape Mount, Bomi, Grand Kru, RiverGee, Lofa Counties. Our gravel road construction works have been the upgrading of existing roads in Liberia. We have done asphalt works in communities in Montserrado County, Pipeline to Johnsonville 6.5km, Johnsonville to White Plains 12km and Johnsonville to Mount Barclay Roads 6.5km. We have expertise in Culvert/box/Bridge culverts installation and bridges.
              </p>
              <p className='about-paragraph-1'>
              </p>
            </div>
            <div style={{ width: "30%" }} className="column-p">
              <img className="photo-collage" src="images/mdmc_collage.jpg" alt="About" />
            </div>
          </div>
        </div>

      </div>

      <h1 className="about-team-header"  ><text style={{ textDecorationLine: 'underline' }}>Leadership Team</text></h1>
      <div className="row">
        <div className="column">
          <div className="card">
            <img className="photo-team" src="images/YoubotyPic.jpg" alt="Youboty" />
            <div className="container">
              <h2>John S Youboty</h2>
              <p className="title">CEO & Founder</p>
              <p>Blend of financial and entrepreneur expertise, building enterprises in the field constructions and IT </p>
              <p>mdmcexpress@gmail.com</p>
              <p><button className="button" onClick={() => alert("Phone : \n+ 231 777512431 \n+231 886512431")}>
                Contact</button>
              </p>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="card">
            {/* <img className="photo-team" src="images/IrfanPic.jpg" alt="Irfan" /> */}
            <img className="photo-team" src="images/mdmc_const_net_1.jpg" alt="Irfan" />
            <div className="container">
              <h2>MDMC Basha</h2>
              <p className="title">IT Head & Director</p>
              <p>IT strategist and advisor with years of IT experience past working with few top organizations in the market</p>
              <p>mdmc.basha@mdmcit.com</p>
              <p><button className="button" onClick={() => alert("Phone : \n+ 231 8817xxxxx \n+ 231 777xxxxx")} > Contact</button></p>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="card">
            <img className="photo-team" src="images/mdmc_const_net_1.jpg" alt="John" />
            <div className="container">
              <h2>John Doe</h2>
              <p className="title">Designer</p>
              <p>Some text that describes me lorem ipsum ipsum lorem.</p>
              <p>john@example.com</p>
              <p><button className="button" onClick={() => alert("Phone : \n+ 231 8817xxxxx \n+231 777xxxxx")}>
                Contact
              </button>
              </p>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="card">
            <img className="photo-team" src="images/mdmc_const_net_1.jpg" alt="John" />
            <div className="container">
              <h2>John Doe</h2>
              <p className="title">Designer</p>
              <p>Some text that describes me lorem ipsum ipsum lorem.</p>
              <p>john@example.com</p>
              <p>
                <button className="button" onClick={() => alert("Phone : \n+ 231 8817xxxxx \n+231 777xxxxx")}>
                  Contact
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>

    </div >

  )
}
